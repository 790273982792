<script>

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import InputValidatorsComponent from '../../shared-component/input-validators-component/input-validators-component.vue'

import {FormValidatorsMixin} from '../../../mixins/form-validators-mixin.js'
import {RegexMixin} from '../../../mixins/regex-mixin.js'

import AuthService from '../../../services/AuthService';
import UsersService from '../../../services/UsersService';
import {User, ProUser} from '../../../model/User';

import router from '../../../router'


// DEV: soucis de vh sur la page (margin negatif sur le container en attendant)
export default {
    name: "RegisterPage",
    components: {
        InputValidatorsComponent,
    },
    mixins: [FormValidatorsMixin, RegexMixin],
    data() {
        return {
            // Form values
            form: {
                name: '',
                email: '',
                pro: null,
                siret: '',
                website: '',
                pwd: '',
                pwdConfirm: ''
            },
            // Form input field, valid or not
            formValid: {
                name: false,
                email: false,
                siret: false,
                website: false,
                pwd: false,
                pwdConfirm: false,
                pro: false,
            },
            // List of option for select
            options: [
                { value: null, text: 'Particulier ou Profesionnel', disabled: true},
                { value: false, text: 'Particulier' },
                { value: true, text: 'Profesionnel' },
            ],
             isInscriptionActive: false,
             errorMessage:undefined,
             registering:false,
        }
    },

    created(){
        this.$emit('headerState', 2);
    },
    methods: {
        onFormSubmit(event) {
            console.log('FormJSON :', JSON.stringify(this.form))

            // Ignoring website & siret if user is not pro
            if(this.form.pro === false) {
                console.log('Register page submit')
                this.formValid.website = true
                this.formValid.siret = true
            }

            if(this.checkForm(this.formValid)) {
                this.registering = true;
                // BACKEND here AJAX call
                console.log('Form valide!', this.formValid)
                AuthService.createNewUser(this.form.email,this.form.pwd).then(
                () => {
                    console.info("Creation utilisateur reussie ! ");
                    this.errorMessage = undefined;
                    let user;

                    if (this.form.pro){
                        user = new ProUser();
                        user.siret = this.form.siret;
                        user.web = this.form.website;
                    } else {
                        user = new User();
                    }

                   
                    user.nom = this.form.name;
                    user.email = this.form.email;
                    user.pro = this.form.pro;
            
                    user.firestoreId = firebase.auth().currentUser.uid;
                    user.adcount = 0;
                    UsersService.setUser(user).then(() => {
                        console.info("utilisateur cree avec success!!");
                        this.registering = false;
                        router.push('MonCompte');
                    } , (error) => { 
                        console.error("creation utilisateur echouee : " + error);
                        this.registering = false;
                    });
                    
                },
                (error) => {
                    this.registering = false;

                    if (error.code == 'auth/email-already-in-use'){
                         this.errorMessage = "Cet email est déjà enregistré dans notre base, veuillez en choisir un autre svp.";
                    } else {
                         this.errorMessage = "Impossible de créer votre compte : " + error.message;
                    }

                   
                    console.error(error);
                }
                );
            }
            else console.log('Form pas valide !', this.formValid)
            event.preventDefault()
        },
        proSelected(){
            this.formValid.pro = true;
          

            this.afterSetValidatedState();
        },
        
        updateRegisterButton(){
            if (this.form.pro){
                this.isInscriptionActive = this.formValid.name && this.formValid.email && this.formValid.siret && this.formValid.website && this.formValid.pwd && this.formValid.pwdConfirm && this.formValid.pro;
            } else {
                 this.isInscriptionActive = this.formValid.name && this.formValid.email && this.formValid.pwd && this.formValid.pwdConfirm && this.formValid.pro;
            }
        },

        afterSetValidatedState(validated, inputValue, fieldName){
              console.info("afterSetValidatedState("+validated + "," + inputValue + "," + fieldName + ")");
            if (fieldName == 'pwdConfirm'){
                this.formValid.pwdConfirm = validated && inputValue.length >=6;
            } else if (fieldName == 'pwd'){
                this.formValid.pwd = validated && inputValue.length >=6;
            }
            this.updateRegisterButton();
            console.info("ok :" + this.isInscriptionActive);
            console.info("name :" , this.formValid.name);
            console.info("email :" , this.formValid.email);
            console.info("siret :" , this.formValid.siret);
            console.info("website :" , this.formValid.website);
            console.info("pwd :" , this.formValid.pwd);
            console.info("pwdConfirm :" , this.formValid.pwdConfirm);
            console.info("pro :" , this.formValid.pro);
        }
       
    }
};
</script>

<style lang="scss">
    @import "register-page.scss";
</style>
<template src="./register-page.html"></template>